import { createRouter, createWebHistory } from "vue-router";
import Store from "../store";

const routes = [
  // {
  //   path: "/",
  //   name: "Landing",
  //   component: () => import("../App")
  // },
  {
    path: "/page",
    name: "ModalView",
    component: () => import("../views/modal/Modal"),
    children: [
      // General modals
      {
        name: "Booking",
        path: "booking",
        component: () => import("../views/page/Booking")
      },
      {
        name: "ContactUs",
        path: "contact-us",
        component: () => import("../views/page/Contact")
      },
      {
        name: "LegalNotice",
        path: "legal-notice",
        component: () => import("../views/page/LegalNotice")
      },
      // Menu modals
      // >> Food
      {
        name: "WeeklyBrunch",
        path: "menu/weekly-brunch",
        component: () => import("../views/menu/Menu")
      },
      {
        name: "WeekendBrunch",
        path: "menu/weekend-brunch",
        component: () => import("../views/menu/Menu")
      },
      {
        name: "Breakfast",
        path: "menu/breakfast",
        component: () => import("../views/menu/Menu")
      },
      // >> Drinks
      {
        name: "SignatureCocktails",
        path: "menu/cocktails",
        component: () => import("../views/menu/Menu")
      },
      {
        name: "DrinksWines",
        path: "menu/drinks-wines",
        component: () => import("../views/menu/Menu")
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  Store.commit("nav/CLOSE_NAV");
  next();
});

export default router;
